'use client';

// core (MUI)
import {
  enUS as enUSCore,
  arSA as arSACore,
} from '@mui/material/locale';
// date pickers (MUI)
import {
  enUS as enUSDate,
} from '@mui/x-date-pickers/locales';
// data grid (MUI)
import {
  enUS as enUSDataGrid,
  arSD as arSDDataGrid,
} from '@mui/x-data-grid/locales';

// ----------------------------------------------------------------------


export const allLangs = [
  {
    value: 'en',
    label: 'English',
    countryCode: 'GB',
    adapterLocale: 'en',
    numberFormat: { code: 'en-US', currency: 'USD' },
    systemValue: {
      components: { ...enUSDate.components, ...enUSDataGrid.components, ...enUSCore.components },
    },
    icon: 'flagpack:gb-nir',
  },
  {
    value: 'ar',
    label: 'Arabic',
    countryCode: 'IQ',
    adapterLocale: 'ar-sa',
    numberFormat: { code: 'ar', currency: 'IQD' },
    systemValue: {
      components: { ...arSACore.components, ...arSDDataGrid.components },
    },
    icon: 'flagpack:iq',
  },
  {
    value: 'ckb',
    label: 'Kurdish',
    countryCode: 'TJ',
    adapterLocale: 'ar-sa',
    numberFormat: { code: 'ar', currency: 'IQD' },
    systemValue: {
      components: { ...arSACore.components, ...arSDDataGrid.components },
    },
    icon: 'flagpack:tj',
  },

];

/**
 * Country code:
 * https://flagcdn.com/en/codes.json
 *
 * Number format code:
 * https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416
 */