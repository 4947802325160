import { IProductPackageItem } from "src/types/product/product-package";
import { getTranslation } from "./get-translation";

export function getProductPackageParentsMultiplier(productPackage: IProductPackageItem): { firstParentProductPackage: IProductPackageItem, multiplier: number } {
  if(!productPackage) return { firstParentProductPackage: productPackage, multiplier: 1 };
  if (!productPackage.parentProductPackage) return { firstParentProductPackage: productPackage, multiplier: productPackage.quantity };

  let multiplier = productPackage.quantity;
  while (productPackage.parentProductPackage) {
    productPackage = productPackage.parentProductPackage;
    multiplier *= productPackage.quantity;
  }
  return { firstParentProductPackage: productPackage, multiplier };
}

export function formatProductPackageParentsDisplayText(productPackage: IProductPackageItem): string {
  if (!productPackage) return '';
  if (!productPackage.parentProductPackage) return `${productPackage.productPackageName?.name && getTranslation(productPackage.productPackageName?.name)} (${productPackage.quantity})`;
  const { firstParentProductPackage, multiplier } = getProductPackageParentsMultiplier(productPackage);
  const displayText = `${productPackage.productPackageName.name && getTranslation(productPackage.productPackageName.name)} (${multiplier} * ${firstParentProductPackage.productPackageName.name && getTranslation(firstParentProductPackage.productPackageName.name)}) `;
  return displayText;
}

