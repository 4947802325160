"use client";  

import { useEffect } from 'react';
import { initializePerformanceObserver } from 'src/utils/initialize-performance-observer'; 

const PerformanceObserverClient = () => {
  useEffect(() => {
    initializePerformanceObserver();
  }, []);

  return null; 
};

export default PerformanceObserverClient;