'use client';

import { useMemo, useEffect, useCallback, useReducer } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { getStorage } from 'src/hooks/use-local-storage';

import { getProductPackageParentsMultiplier } from 'src/utils/product-package-parent';

import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
import { useGetClientsPagination } from 'src/api/client/client';

import { ICheckoutItem } from 'src/types/checkout';
import { IClientItem } from 'src/types/client/client';
import { ICompanyWarehouseProductItem } from 'src/types/company/company-warehouse-product';

import { CheckoutContext } from './checkout-context';
import { ClientResponseDto } from 'modules/client/clients/dtos/client-response.dto';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'checkout';

const initialState = {
  companyWarehouseProducts: [], // To keep track of stock
  currencyCode: 'IQD',
  client: null,
  activeStep: 0,
  items: [],
  subTotal: 0,
  totalAmount: 0,
  discount: 0,
  shipping: 0,
  billing: null,
  totalItems: 0,
};

function checkoutReducer(state: any, action: { type: any; key: any; value: any; }) {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, [action.key]: action.value };
    case 'RESET_STATE':
      return initialState;
    // Add more cases as needed
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export function CheckoutProvider({ children }: Props) {
  const router = useRouter();

  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  const setState = (key: string, value: string | number | any[] | ClientResponseDto) => {
    dispatch({ type: 'SET_STATE', key, value });
  };

  const resetState = () => {
    dispatch({
      type: 'RESET_STATE',
      key: undefined,
      value: undefined
    });
  };

  // const { clients } = useGetClientsPagination(0, 1, '', '', 'ASC');
  // useEffect(() => {
  //   setState('client', clients[0]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clients])
  const onGetCart = useCallback(() => {
    const totalItems: number = state.items.reduce(
      (totalAmount: number, item: ICheckoutItem) => totalAmount + item.quantity,
      0
    );

    const subTotal: number = state.items.reduce(
      (totalAmount: number, item: ICheckoutItem) => totalAmount + item.quantity * item.price,
      0
    );
    setState('currencyCode', state.currencyCode);
    setState('client', state.client);
    // setState('companyWarehouseProducts', state.companyWarehouseProducts);

    setState('subTotal', subTotal);
    setState('totalItems', totalItems);
    // setState('billing', state.activeStep === 1 ? null : state.billing);
    // setState('discount', state.items.length ? state.discount : 0);
    // setState('shipping', state.items.length ? state.shipping : 0);
    setState('totalAmount', state.subTotal - state.discount + state.shipping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.items,
    state.activeStep,
    state.billing,
    // state.discount,
    // state.shipping,
    state.subTotal,
  ]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  function checkIfSame(item: ICheckoutItem, newItem: ICheckoutItem) {
    return item.id === newItem.id && item.companyWarehouseProduct.id === newItem.companyWarehouseProduct.id && item.productPackage.id === newItem.productPackage.id
  }

  function checkIfSameCompanyWarehouseProduct(item: ICheckoutItem, newItem: ICheckoutItem) {
    return item.id === newItem.id && item.companyWarehouseProduct.id === newItem.companyWarehouseProduct.id
  }

  const updateCompanyWarehouseProductStocks = useCallback(
    (item: ICheckoutItem, newStock: number) => {
      // const companyWarehouseProducts = [...state.companyWarehouseProducts]
      state.companyWarehouseProducts.forEach((companyWarehouseProduct: ICompanyWarehouseProductItem) => {
        if (companyWarehouseProduct.id === item.companyWarehouseProduct.id) {
          companyWarehouseProduct.stock += newStock
          console.log('companyWarehouseProduct.stock', companyWarehouseProduct.stock)
        }
      });

      setState('companyWarehouseProducts', [...state.companyWarehouseProducts]);

    }, [setState, state.companyWarehouseProducts]
  );

  const onAddToCart = useCallback(
    (newItem: ICheckoutItem) => {
      console.log('newItem')
      console.log(newItem)

      const updatedItems: ICheckoutItem[] = state.items.map((item: ICheckoutItem) => {
        if (checkIfSame(item, newItem)) {
          updateCompanyWarehouseProductStocks(newItem, - (getProductPackageParentsMultiplier(newItem.productPackage).multiplier * newItem.quantity))
          const quantity = item.quantity + newItem.quantity
          const totalAmount = (item.price * quantity)
          return {
            ...item,
            // colors: uniq([...item.colors, ...newItem.colors]),
            quantity,
            totalAmount,
            subTotal: totalAmount
          };
        }
        updateCompanyWarehouseProductStocks(newItem, - (getProductPackageParentsMultiplier(newItem.productPackage).multiplier * newItem.quantity))
        return item;
      });

      if (!updatedItems.some((item: ICheckoutItem) => checkIfSame(item, newItem))) {
        updatedItems.push(newItem);
      }

      setState('items', updatedItems);
    },
    [state.items, setState, updateCompanyWarehouseProductStocks]
  );

  const onDeleteCart = useCallback(
    (selectedItem: ICheckoutItem) => {
      const updatedItems = state.items.filter((item: ICheckoutItem) => !checkIfSame(item, selectedItem));
      updateCompanyWarehouseProductStocks(selectedItem, + selectedItem.quantity)

      setState('items', updatedItems);
    },
    [state.items, updateCompanyWarehouseProductStocks, setState]
  );

  const onBackStep = useCallback(() => {
    setState('activeStep', state.activeStep - 1);
  }, [setState, state.activeStep]);

  const onNextStep = useCallback(() => {
    setState('activeStep', state.activeStep + 1);
  }, [setState, state.activeStep]);

  const onGotoStep = useCallback(
    (step: number) => {
      setState('activeStep', step);
    },
    [setState]
  );

  const onIncreaseQuantity = useCallback(
    (selectedItem: ICheckoutItem) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (checkIfSame(item, selectedItem)) {
          updateCompanyWarehouseProductStocks(selectedItem, - (getProductPackageParentsMultiplier(selectedItem.productPackage).multiplier * 1))
          // const quantity = item.quantity + (getProductPackageParentsMultiplier(item.productPackage) * 1)
          const quantity = item.quantity + 1
          const totalAmount = (item.price * quantity)
          return {
            ...item,
            quantity,
            totalAmount,
            subTotal: totalAmount
          };
        }
        return item;
      });

      setState('items', updatedItems);
    },
    [state.items, setState, updateCompanyWarehouseProductStocks]
  );

  const onDecreaseQuantity = useCallback(
    (selectedItem: ICheckoutItem) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (checkIfSame(item, selectedItem)) {
          updateCompanyWarehouseProductStocks(selectedItem, + (getProductPackageParentsMultiplier(selectedItem.productPackage).multiplier * 1))
          // const quantity = item.quantity - (getProductPackageParentsMultiplier(item.productPackage) * 1)
          const quantity = item.quantity - 1
          const totalAmount = (item.price * quantity)
          return {
            ...item,
            quantity,
            totalAmount,
            subTotal: totalAmount
          };
        }
        return item;
      });

      setState('items', updatedItems);
    },
    [state.items, setState, updateCompanyWarehouseProductStocks]
  );

  const onCreateBilling = useCallback(
    (client: IClientItem) => {
      setState('billing', client);

      onNextStep();
    },
    [onNextStep, setState]
  );

  // const onApplyDiscount = useCallback(
  //   (discount: number) => {
  //     setState('discount', discount);
  //   },
  //   [setState]
  // );

  // const onApplyShipping = useCallback(
  //   (shipping: number) => {
  //     setState('shipping', shipping);
  //   },
  //   [setState]
  // );

  const onCurrencyCodeChange = useCallback(
    (currencyCode: string) => {
      setState('currencyCode', currencyCode);
    },
    [setState]
  );

  const onClientChange = useCallback(
    (client: IClientItem) => {
      setState('client', client);
    },
    [setState]
  );

  const getCompanyWarehouseProductStock = useCallback(
    (companyWarehouseProduct: ICompanyWarehouseProductItem): number => {
      if (!companyWarehouseProduct) return 0
      return state.companyWarehouseProducts.find((companyWarehouseProduct_: ICompanyWarehouseProductItem) => companyWarehouseProduct_.id === companyWarehouseProduct.id)?.stock
    }
    , [state.companyWarehouseProducts]
  );

  const updateCompanyWarehouseProductStock = useCallback(
    (id: string, newStock: number) => {
      setState('companyWarehouseProducts', state.companyWarehouseProducts.map((companyWarehouseProduct: ICompanyWarehouseProductItem) => (companyWarehouseProduct.id === id ? { ...companyWarehouseProduct, stock: newStock } : companyWarehouseProduct))
      );
    }, [setState, state.companyWarehouseProducts]
  );

  const addCompanyWarehouseProduct = useCallback(
    (newCompanyWarehouseProduct: ICompanyWarehouseProductItem) => {
      if (!newCompanyWarehouseProduct) return
      console.log('newCompanyWarehouseProduct', newCompanyWarehouseProduct)

      const companyWarehouseProductExists = state.companyWarehouseProducts.some(
        (companyWarehouseProduct: ICompanyWarehouseProductItem) => companyWarehouseProduct.id === newCompanyWarehouseProduct.id
      );
      console.log('companyWarehouseProductExists', companyWarehouseProductExists)
      if (!companyWarehouseProductExists) {
        console.log('companyWarehouseProductExists', companyWarehouseProductExists)
        setState('companyWarehouseProducts', [...state.companyWarehouseProducts, newCompanyWarehouseProduct]);
      } else {
        // updateCompanyWarehouseProductStock(newCompanyWarehouseProduct.id, newCompanyWarehouseProduct.stock)
      }
    },
    [setState, state.companyWarehouseProducts]
  );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      resetState();
      router.replace(paths.product.root);
    }
  }, [completed, resetState, router]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      getCompanyWarehouseProductStock,
      addCompanyWarehouseProduct,
      updateCompanyWarehouseProductStock,
      //
      onAddToCart,
      onDeleteCart,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onCreateBilling,
      // onApplyDiscount,
      // onApplyShipping,
      onCurrencyCodeChange,
      onClientChange,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
    }),
    [
      completed,
      //
      getCompanyWarehouseProductStock,
      addCompanyWarehouseProduct,
      updateCompanyWarehouseProductStock,
      //
      onAddToCart,
      // onApplyDiscount,
      // onApplyShipping,
      onCurrencyCodeChange,
      onClientChange,
      onBackStep,
      onCreateBilling,
      onDecreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onIncreaseQuantity,
      onNextStep,
      onReset,
      state,
    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}