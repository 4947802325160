export * from './all-langs';

export * from './use-locales';

export * from './i18n-provider';

export * from './config-locales';

export * from './localization-provider';

export * from './utils/number-format-locale';


export type WebsiteLanguage = {
	value: string;
	label: string;
	countryCode: string;
	adapterLocale: string;
    numberFormat: { code: string; currency: string };
    systemValue: any;
    icon: string;
  };
  