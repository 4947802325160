'use client';

import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'src/components/snackbar';

import { allLangs } from './all-langs';
import { fallbackLng, changeLangMessages as messages } from './config-locales';

import type { LanguageValue } from './config-locales';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export function useTranslate(ns?: string) {
  // const router = useRouter(); // No longer needed

  const { t, i18n } = useTranslation(ns);

  const fallback = allLangs.filter((lang) => lang.value === fallbackLng)[0];

  const currentLang = allLangs.find((lang) => lang.value === i18n.resolvedLanguage);
 
  const onChangeLang = useCallback(
    async (newLang: LanguageValue) => {
      try {
        const langChangePromise = i18n.changeLanguage(newLang);

        const currentMessages = messages[newLang] || messages.en;

        toast.promise(langChangePromise, {
          loading: currentMessages.loading,
          success: () => currentMessages.success,
          error: currentMessages.error,
        });

        if (currentLang) {
          dayjs.locale(currentLang.adapterLocale);
        }

        localStorage.setItem('selectedLanguage', newLang);

  
       } catch (error) {
        console.error(error);
      }
    },
    [currentLang, i18n]
  );

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage') as LanguageValue;
    if (savedLanguage && savedLanguage !== i18n.resolvedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return {
    t,
    i18n,
    onChangeLang,
    currentLang: currentLang ?? fallback,
  };
}